/* You can add global styles to this file, and also import other style files */
@use 'custom-theme.scss';

$primary: #8ac220;
$accent: #20629c;
$warn: #F44336;

html, body { height: 100%;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media(max-width: 600px) {
  body {
    font-size: 16px;

    p {
      font-size: 14px;
    }
  }
}

//General
.align-items-center {
  display: flex;
  align-items: center;
}

.align-items-left {
  align-items: start;
}

.justify-items-left{
  justify-content: left;
}

.information-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start; /* Aligns content to the start (left) */
  width: 100%;
}
.back-btn {
  margin: 10px 0 20px 0;
}

/* Mat Card*/
.container {
  padding-bottom: 50px;
  
  mat-card {
    margin-top: 24px;
    
    @media(max-width: 600px) {
      margin-top: 12px;
    }
  
    mat-card-header {
      padding: 20px;

      @media(max-width: 600px) {
        padding: 12px;
      }
    
      mat-card-title {
        font-size: large;
        font-weight: 500;

        @media(max-width: 600px) {
          font-size: medium;
          font-weight: 400;
        }
      }
    }
  }
} 

//mat table
.mat-elevation-z8 {    
  overflow-x: auto;
  width: 100%;
}
.scrollable-form
{
  max-height: 600px;
  overflow-y: auto;
    @media(max-height: 600px) {
      max-height: 400px;
    }
}


//dialog
.mat-mdc-dialog-title {
  font-size: large !important;
  padding-top: 8px !important;
  display: flex !important;
  align-items: center !important;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

//flex box
.flex-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 599px) {
    flex-direction: column;
    gap: 5px;
  }

  .flex-50 {
    flex: 1 1 calc(50% - 10px); 
    box-sizing: border-box;
  }
  .flex-66 {
    flex: 1 1 calc(66.66% - (40px / 3));  
    box-sizing: border-box;
  }
  .flex-33 {
    flex: 1 1 calc(33.33% - (40px / 3));  
    box-sizing: border-box;
  }

  .flex-25 {
    flex: 1 1 calc(25% - (40px / 4));  
    box-sizing: border-box;
  }

  .flex-20 {
    flex: 1 1 calc(20% - (40px / 4));  
    box-sizing: border-box;
  }
  
  .flex-1 {
    flex: 1 1 0;
  }
}

//column
.column {
  display:flex;
  flex-direction: column;
}

.flex-end-actions {
  display: flex;
  justify-content: end !important;
}

//buttons
.btn-full {
  width: 100%;
}

//text colors
.text-primary {
  color: $primary
}

.text-accent {
  color: $accent
}

.text-warn {
  color: $warn
}
//non theme colours
.text-white {
  color: white
}
.text-secondary {
  color: #9e9e9e
}
//text alignment
.text-align-end{
  text-align: end;
}
.text-align-center {
  text-align: center;
}
//loading Spinner
.loading-container 
{
    display:flex;
    justify-content:center;
    margin-top: 20px;
    margin-bottom: 20px;
}

//centering content
.content-center
{
  display:flex;
  justify-content: center;
  align-items: center;
}
//table hover
.active-row {
  background-color: #5b800f;
}
.table-hover {
  transition: background-color 0.3s ease;
  }

.table-hover:hover {
  background-color: #8ac220;
  }
.active-table-hover {
  transition: background-color 0.3s ease;
  }

.active-table-hover:hover {
  background-color: #8ac220;
  }
//misc
.w-100
{
  width: 100%;
}
//Margins/Padding
.p-1
{
  padding: 1rem;
}
.pt-0
{
  padding-top: 0rem;
}
.pb-0
{
  padding-bottom: 0rem;
}
.pb-1
{
  padding-bottom: 1rem;
}
.py-1
{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1
{
  padding-left: 1rem;
  padding-right: 1rem;
}
.m-1
{
  margin: 1rem;
}
.mt-0
{
  margin-top: 0rem;
}
.mt-1
{
  margin-top: 1rem;
}
.mb-1
{
  margin-bottom: 1rem;
}
.mx-1
{
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-1
{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ms-1
{
  margin-left: 0.5rem;
}
.me-1
{
  margin-right:  1rem;
}
//overflows
.hide-overflow
{
    overflow-y: hidden;
}

.highlight {
  background-color: $primary;
}